import {RouterModule, Routes} from "@angular/router";
import {StationNotLoadedPage} from "./app/station-not-loaded.page";
import {PageNotFoundPage} from "./app/page-not-found.page";
import {NgModule} from "@angular/core";

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'station_error',
        component: StationNotLoadedPage
      },
      {
        path: 's',
        loadChildren: () => import('./station/station.module').then(m=>m.StationModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 's'
      },
    ]
  },
  {path: '**', component: PageNotFoundPage},
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', enableTracing: false }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
