import {Component} from '@angular/core';

const template = `
<h2>We're sorry, we couldn't find that page!</h2>
<p>You can hit your browsers 'back' button to return from where you were, or <a routerLink="/">navigate to the home page.</a> </p>.
`

@Component({
  template: template
})
export class PageNotFoundPage {
}
