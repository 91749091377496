import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {AppConfig, HngCommonModule} from "@hidat/ng-common";
import {environment} from "../environments/environment";
import {EventTrackerConfig, EventTrackerModule, PlayerApiModule} from "@splixer/player";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {HttpClientModule} from "@angular/common/http";
import {AppPagesModule} from "./pages/pages.module";
import {KnkxRootPage} from "./pages/app/root-page/root.page";
import {KnkxCoreModule} from "./core/core.module";

const appConfig: AppConfig = {
  appCode: 'knkx-player',
  appName: 'KNKX On Demand',
  apiUrl: environment.splixerUrl,
  userApiUrl: ''
}

const eventTrackerConfig: EventTrackerConfig = {
  primaryTrackingCode: environment.defaultGaTrackingId
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    HngCommonModule.forRoot(appConfig),
    HttpClientModule,
    PlayerApiModule.forRoot(appConfig),
    NgxGoogleAnalyticsModule.forRoot(environment.defaultGaTrackingId),
    NgxGoogleAnalyticsRouterModule,
    EventTrackerModule.forRoot(eventTrackerConfig),
    KnkxCoreModule,
    //KnkxSharedModule,
    AppPagesModule
  ],
  bootstrap: [KnkxRootPage]
})
export class AppModule {}
