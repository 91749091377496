import {Component} from "@angular/core";


@Component({
  selector: 'knkx-root-page',
  templateUrl: 'root.page.html'
})
export class KnkxRootPage {

}
