import {Component} from "@angular/core";

const template = `
<div>
    <h1>Error Loading Station Information</h1>
    <div>It looks like we ran into an error trying to start the application.  Please make sure you are online and scripts have not been blocked for this page.</div>
</div>
`
@Component({
  template: template
})
export class StationNotLoadedPage {

}
