import {KnkxRootPage} from "./root-page/root.page";
import {PageNotFoundPage} from "./page-not-found.page";
import {StationNotLoadedPage} from "./station-not-loaded.page";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {KnkxCoreModule} from "../../core/core.module";

const components = [
  KnkxRootPage,
  PageNotFoundPage,
  StationNotLoadedPage,
]
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    KnkxCoreModule
  ],
  declarations: components,
  exports: [KnkxRootPage]
})
export class KnkxRootModule {}
