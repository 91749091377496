import {NgModule} from "@angular/core";
import {AppRoutingModule} from "./app-routing.module";
import {KnkxRootModule} from "./app/root.module";

@NgModule({
  imports: [
    KnkxRootModule,
    AppRoutingModule
  ]
})
export class AppPagesModule {}
